@charset "utf-8";

// Update some of Bulma's component variables
@use "../node_modules/bulma/sass" with
(
    $body-background-color: #693939,
    $navbar-background-color: #363636,
    $menu-label-color: #cccc66,
    $modal-content-width: 60vw,
);
//     $navbar-item-color: #00ffb3,
//     $menu-item-color: #aaaa88,

// Import Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Gorditas&display=swap');

/* Déclaration de la hauteur de la navbar en tant que variable CSS5 */
:root
{
    --nav-height: 3.25rem;
}

/* Arrière-plan des sous-titres en noir semi-transparent */
.vjs-text-track-cue div
{
    background-color: rgba(0, 0, 0, 0.5) !important;
}

/* Cache le bouton "Caption Settings" */
.vjs-texttrack-settings
{
    display: none;
}

/* La bonne hauteur fixe pour le viewport */
.wrapper
{
    height: calc(100vh - var(--nav-height));
}

nav .amestream
{
    font-family: 'Gorditas';
}

aside.menu
{
    background: linear-gradient(to right, transparent, 5%, rgba(0, 0, 0, 0.5), 95%, transparent);
    border-radius: 5px;
}

.modal-content
{
    padding: 1%;
}

span.shortcut-label
{
    text-decoration: underline;
}

span.shortcut-key
{
    color: red;
    font-variant-caps: small-caps;
    font-weight: 900;
}

.help-disclaimer
{
    background-color: transparent;
    text-align: center;
    // color: $menu-label-color;
    font-weight: 900;
    font-size: 2rem;
}